import React, { useState, useEffect } from "react";
import SplitLeft from "./components/SplitLeft";
import SplitRight from "./components/SplitRight";
import axios from "axios";

export default function App() {
  const [data, setData] = useState({ about: [], experiences: [] });
  useEffect(() => {
    let fetchData = async () => {
      let res = await axios.get("https://notion-api.splitbee.io/v1/table/" + process.env.REACT_APP_NOTION_TOKEN);

      let about = [];
      let experiences = [];
      let intro = "";

      for(let i = 0; i < res.data.length; i++){
          delete res.data[i].id;
          if(res.data[i].status){
              res.data[i].status = res.data[i].status[0];
          }
          if(res.data[i].type === "Intro"){
              delete res.data[i].heading;
              intro = res.data[i].name;
          }else if(res.data[i].heading[0] === "experience"){
              delete res.data[i].heading;
              experiences.push(res.data[i]);
          }else{
              delete res.data[i].heading;
              about.push(res.data[i]);
          }
          if(res.data[i].technologies){
              res.data[i].technologies = res.data[i].technologies.join(", ")
          }
      }
      const info = {"about":about, "experiences":experiences, "intro":intro};
      console.log(info)
      setData(info);
    };
    fetchData();
  }, []);

  return (
    <div className="app">
      <SplitLeft info={data.about}/>
      <SplitRight info={data}/>
    </div>
  );
}
