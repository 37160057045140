import React from "react";
import ShowMore from "./ShowMore";
import ExperienceSection from "./ExperienceSection";


function renderButton(key,value,statusIndex) {
  const [isClicked, handleChange] = ShowMore();
  return (
    <div
      value={isClicked}
      onClick={handleChange}
      className="experience-box"
      key={statusIndex}
    >
      <div className="experience">
        <div
          className={!isClicked || value.length <= 2 ? "clip-item" : ""}
        >
          <h4>{key + (key === "Project"?"s":"")}</h4>
          <ExperienceSection data={value} key={key} />
        </div>
      </div>
    </div>
  )
}

function Experience(props) {
  const data = {};
  const experiences = props.experiences.filter((item) => item.display);

  experiences.sort((a,b) => (Date.parse(a.startDate) > Date.parse(b.startDate)) ? -1 : 1)

  experiences.forEach((experience) => {
    if (!data.hasOwnProperty(experience.status)) {
      data[experience.status] = [experience];
    } else {
      data[experience.status].push(experience);
    }
  });

  console.log(data)
  if(!("Work" in data || "Experience" in data)) {
    return <div></div>
  } else {
    return (
      <div>
        {renderButton("Work", data["Work"],0)}
        {renderButton("Project", data["Project"],1)}
      </div>
    );
  }
  
}

export default Experience;
